hr.color-bar {
  background-image: url(../Images/brandColor.svg);
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  height: 100%;
  width: 100%;
  margin: 0;
}

.bottom-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #90BC54 !important;
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #90BC54 !important;
}

.MuiInputAdornment-positionEnd {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

body {
  background-color: white;
  margin: 0px;
  overflow: none;
}

