.container {
  max-width: 1140px;
  margin-top: 120px;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 120px;
  overflow: visible;
}

.MuiChip-labelSmall {
  padding-left: 11px;
  padding-right: 11px;
  color: white;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  height: 17px;
  line-height: 17px;
}

.MuiChip-sizeSmall {
  height: 17px !important;
}

.status-delivered {
  background-color: #6BBEDC;
}

.MuiCardContent-root:last-child {
  padding-bottom: 16px;
}

.summarySubTitle {
  font-size: 14px;
  color: #666666;
}

dt {
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  float: left;
  width: 120px;
}

dd {
  margin-bottom: 5px;
}

.date {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #666666;
}


.summary.MuiCard-root {
  padding: 28px;
    height: 207px;
    margin: auto;
    min-width: 500px;
    max-width: 1300px;
    border: 1px solid rgb(224, 224, 222);
    box-sizing: border-box;
    border-radius: 0px !important;
}

.summary.MuiCard-root:hover {
  border: 1px solid #8BC53F;
  cursor: pointer;
  background-color:#f9f9f9;
}

.summaryTitle {
  font-weight: 700;
  line-height: 22px;
  padding-bottom: 3px;
}

dd {
  color: rgb(102, 102, 102);
  font-size: 14px;
  line-height: 19px;
}

.summaryHeader {
  display: flow-root;
}

.pagination {
  border: 1px solid #E0E0DE;
  box-sizing: border-box;
  background: #FFFFFF;
  height: 58px;
  padding-left: 28px;
  padding-top: 12px;
}
.messageDocuments {
  border: 1px solid #E0E0DE;
  box-sizing: border-box;
  background: #FFFFFF;
  height: 58px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}
