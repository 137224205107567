body {
  background: linear-gradient(120.85deg, #FDFFFB 2.75%, #F7F8F5 99.79%);
  min-width: 525px;
  overflow-x: auto;
}

.container {
  max-width: 1140px;
  margin-top: 120px;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 120px;
  overflow: visible;
}

.details.MuiCard-root {
  padding: 28px;
  height: 122px;
  margin: auto;
  max-width: 1300px;
  border: 1px solid rgb(224, 224, 222);
  box-sizing: border-box;
  border-radius: 0px !important;
  min-width: 500px;
}

.info {
  width: 50%;
  float: left;
  margin-top: 0px;
}

.download {
  width: 50%;
  float: right;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.pdf.MuiCard-root {
  padding: 28px;
  margin-bottom: 60px;
  max-width: 1300px;
  border: 1px solid rgb(224, 224, 222);
  box-sizing: border-box;
  border-radius: 0px !important;
}

.pdf-download {
  background-color: #8BC53F !important;
}


.react-pdf__Page__canvas {
    margin: 0 auto;
}

.detailsHeader {
  display: flow-root;
  font-weight: bold;
}
