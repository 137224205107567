body {
  background-color: #f5f5f5;
  font-family: 'Open Sans'
}

.right {
  float: right
}

.left {
  float: left
}
